body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Common */

.full-width {
  width: 100%;
}

.purple-bg {
  background-color: var(--shareable-color) !important;
}

/* Item View */

.item-view {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 30rem;
  margin: auto;
  gap: 1.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  min-height: 70vh;
}

.item-view .heading {
  align-self: center;
  margin-bottom: 1rem;
}

/* OAuthScreen */

.permission-box {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 2rem;
}

/* PeriodSelector */

/* Temporary: Remove */
.period-selector {
  border: 1px solid #ccc;
}

.period-wrapper {
  width: 3.5rem;
}

.period {
  padding-right: 0.5rem !important;
  padding-left: 0.75rem !important;
}

.granularity {
  width: 7.5rem;
}

/* DayFields */

.start-date-wrapper {
  width: 10rem;
}

/* WeekFields */

.weekday {
  width: 1.75rem;
}

.checkbox-label {
  margin: 0px;
}

.start-week {
  width: 8.5rem;
}

/* MonthFields */

.month-day {
  width: 5rem;
}

.month-week-number {
  width: 5rem;
}

.month-weekday {
  width: 8rem;
}

.start-month {
  width: 7.5rem;
}

/* MonthFields */

.month {
  width: 8rem;
}

.start-year {
  width: 6rem;
}

/* TimezoneSelector */

.timezone {
  width: 15rem;
}

.timezone div[data-testid="dropdown-option-content"] {
  font-size: 0.75rem;
  text-align: left;
}

.timezone > div > div > div > div {
  padding-left: 0.25rem;
}

/* RecurrenceVisualizer */

.recurrence-calendar {
  background: white;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  border-radius: 5px;
  color: #888;
  position: relative;
  width: fit-content;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.recurrence-calendar .month-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 1rem;
  font-size: 0.75rem;
  margin-top: 0.5rem;
}

.recurrence-calendar .month-nav i {
  cursor: pointer;
}

.recurrence-calendar .weekdays {
  display: flex;
  list-style: none;
  margin-top: 1.5rem;
}

.recurrence-calendar .weekdays li {
  flex: 1;
  text-align: center;
  color: #999;
}

.recurrence-calendar .date-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  margin-top: 1rem;
  gap: 0.5rem;
}

.recurrence-calendar .date-cell {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  color: #bbb;
  font-size: 0.8125rem;
  padding: 0.25rem;
}

.recurrence-calendar .date-cell.selected {
  color: white;
  background-color: #328ceb;
  border-radius: 50%;
}

.recurrence-calendar .current-month-date {
  color: #333;
}

/* NextScheduledDate */

.next-scheduled-date {
  background: var(--primary-selected-color);
  border-radius: var(--border-radius-medium);
  color: var(--primary-text-color);
  font-size: 0.875rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  text-align: left;
}

/* DuplicateItemOption */

.with-updates {
  width: 12rem;
}

.use-latest-item {
  width: 8rem;
}

/* TargetGroupSelector */

.target-group {
  width: 10rem;
}

/* StatusSelector */

.status {
  width: 7.5rem;
}

.status-value {
  width: 10rem;
}

.status > div > div > div > div,
.status-value > div > div > div > div {
  padding-left: 0.25rem;
}

/* DueDateSelector */

.due-date {
  width: 7.5rem;
}

.due-date > div > div > div > div {
  padding-left: 0.25rem;
}

/* NotificationOptionSelector */

.notification {
  width: 15rem;
}

/* CustomDescriptionBuilder */

.description-box {
  width: 100%;
  min-height: 6rem;
  resize: vertical;
  padding: 0.5rem;
  font: inherit;
  font-size: 0.875rem;
}

.variable-container {
  background: #f4f4f4;
  padding: 1rem;
  border-radius: 10px;
}

.variable {
  border: 1px solid gray;
  border-radius: 5px;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  background: white;
  cursor: pointer;
  color: #323338;
}

.variable-selected {
  background: #28c882;
  color: white;
  border-color: transparent;
}

.variable-disabled {
  pointer-events: none;
  opacity: 0.8;
}

.variable:hover {
  background: #ddd;
  color: #323338;
  border-color: gray;
}

/* ButtonGroup */

.button-group {
  margin-top: 1rem;
}

.button {
  width: 150px;
}

/* AddEntityModal */

.modal {
  margin-top: 1rem;
}

/* ScheduleStatus */

.schedule-status {
  width: 100%;
}

/* MissingFieldsWarning */

.missing-fields-warning {
  background: var(--negative-color-selected);
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
}

.missing-fields-warning ul {
  margin: 0.5rem;
  padding: 0 0.5rem;
}

/* ValidationError */

.validation-error {
  width: 100%;
}

/* SubscribeNudge */

.subscribe-nudge {
  background: var(--warning-color-selected);
  padding: 0.75rem 0rem;
  border-radius: 0.5rem;
}

/* Board View */

.board-view {
  display: flex;
  flex-direction: column;
  max-width: 95%;
  margin: 2rem auto;
  gap: 2rem;
  min-height: 90vh;
}

/* Pricing Plans */

.plan-box {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 1.5rem 2rem;
}

/* Calendar View */

.calendar-view {
  display: flex;
  align-items: center;
  justify-content: center;
}

.calendar-view .center {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  background: #373c4f;
  padding: 1rem;
  border-radius: 1rem;
}

.calendar {
  width: 35rem;
  background: white;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-radius: 5px;
  color: #888;
  position: relative;
}

.calendar::before,
.calendar::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%;
  width: 0.5rem;
  height: 97%;
  background-color: #ccc;
  border-radius: 0 5px 5px 0;
  transform: translateY(-50%);
}

.calendar::before {
  height: 94%;
  background-color: #aaa;
  left: calc(100% + 0.5rem);
}

.calendar .month-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 1rem;
  margin-top: 2rem;
}

.calendar .month-nav i {
  cursor: pointer;
}

.calendar .weekdays {
  display: flex;
  list-style: none;
  margin-top: 3rem;
  font-weight: bold;
}

.calendar .weekdays li {
  flex: 1;
  text-align: center;
  color: #999;
}

.calendar .date-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  margin-top: 1.5rem;
}

.calendar .date-cell {
  height: 5rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border: 1px solid #eaeaea;
  position: relative;
  font-weight: bold;
  color: #bbb;
}

.calendar .current-month-date {
  color: #b38add;
}

.calendar .date-cell .date-num-tasks {
  font-size: 0.6875rem;
  position: absolute;
  bottom: 5px;
  color: white;
  background: #b38add;
  border-radius: 2px;
  padding: 0.1rem 0.4rem;
}

.calendar .date-cell.selected {
  font-size: 1.75rem;
  font-weight: bold;
  color: white;
  background: #b38add;
}

.calendar .date-cell.selected .date-num-tasks {
  color: #b38add;
  background: white;
}

.date-schedules {
  width: 25rem;
  height: 35rem;
}

.date-schedules .day-date {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  color: white;
  padding-left: 1rem;
  padding-right: 1rem;
}

.date-schedules .schedules {
  list-style: none;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  height: 80%;
  overflow-y: auto;
  gap: 0.5rem;
}

.date-schedules .schedules::-webkit-scrollbar {
  width: 5px;
}

.date-schedules .schedules::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.2);
}

.date-schedules .schedules::-webkit-scrollbar-thumb {
  background: #b38add;
}

.date-schedules .schedule > div {
  padding: 0.5rem;
  border-radius: 5px;
  margin-right: 0.5rem;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  background: linear-gradient(90deg, #3f4458, transparent);
}

.date-schedules .schedule > div:hover {
  background: linear-gradient(90deg, #b38add, transparent);
}

.date-schedules .item-name {
  color: #b38add;
  font-weight: bold;
}

.date-schedules .schedule > div:hover .item-name {
  color: white;
}

.date-schedules .board-name {
  font-size: 0.875rem;
}

.date-schedules .recurrence {
  font-size: 0.75rem;
}
